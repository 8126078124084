@charset "UTF-8";

@font-face {
  font-family: 'wedding';
  src: url('fonts/wedding.eot');
  src: url('fonts/wedding.eot?#iefix') format('embedded-opentype'),
    url('fonts/wedding.woff') format('woff'),
    url('fonts/wedding.ttf') format('truetype'),
    url('fonts/wedding.svg#wedding') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'wedding' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'wedding' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-rings:before {
  content: '\61';
}
.icon-hearts:before {
  content: '\62';
}
.icon-wine-glasses:before {
  content: '\63';
}
.icon-bus:before {
  content: '\66';
}
.icon-angle-down:before {
  content: '\67';
}
.icon-angle-left:before {
  content: '\68';
}
.icon-angle-right:before {
  content: '\69';
}

.app {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color: #000000;
}

.app-content {
  padding-bottom: 20px;
}

.d-none {
  display: none !important;
}

@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-none {
    display: none !important;
  }
}
