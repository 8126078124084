.map-section {
  min-height: 650px;
  display: flex;
  box-shadow: 1px 10px 40px rgba(0, 0, 0, 0.4);
  flex-wrap: wrap;
  margin-top: 30px;
}

.map-section-wrapper {
  min-height: 450px;
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
}

@media (min-width: 992px) {
  .map-section-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.map-section a {
  color: #890f3a;
  text-decoration: none;
}

.map-section a:hover {
  text-decoration: underline;
}

.map-location-wrapper{
  margin-top:5%
}