.map-location-wrapper {
  flex: 0 0 100%;
  padding: 20px;
  box-sizing: border-box;
  font-size: 16px;
}

.map-location-title h2 {
  font-family: 'Great Vibes', cursive;
  font-weight: normal;
  font-size: 46px;
  margin: 0;
  padding: 0 15px;
  display: inline;
  color: #000000;
  border-bottom: 3px solid;
  border-color: transparent;
}

.map-location-title i {
  margin-right: 15px;
  color: #890f3a;
}

.map-location-wrapper-hovered .map-location-title h2 {
  border-color: #890f3a;
}

@media (min-width: 768px) {
  .map-location-wrapper {
    flex: 0 0 50%;
    padding: 30px 20px;
    box-sizing: border-box;
  }

  .map-location-title h2 {
    font-size: 56px;
  }
}
