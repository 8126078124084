@import "~react-image-gallery/styles/css/image-gallery.css";

.venue{
    min-height: 500px;
    margin: 20px;
    box-sizing: border-box;
}

.venueSection{
    display: flex;
    margin-top: 1%;
    align-items: center;
}

.gallery{
    width:45%;
    /* background-color:#ddc; */
    background-image: url("./woodgrain.png");
    background-repeat: repeat;
    border:solid 3vmin #eee;
    border-bottom-color:#663402;
    border-left-color:#894602;
    border-radius:2px;
    border-right-color:#894602;
    border-top-color:#663402;
    box-shadow:0 0 2px 0 rgba(0,0,0,.25) inset, 0 2px 5px 2px rgba(0,0,0,.25);
    box-sizing:border-box;
    display:inline-block;
    position:relative;
    text-align:center;
    /* &:before {
      border-radius:2px;
      bottom:-2vmin;
      box-shadow:0 2px 5px 0 rgba(0,0,0,.25) inset;
      content:"";
      left:-2vmin;
      position:absolute;
      right:-2vmin;
      top:-2vmin;
    }
    &:after {
      border-radius:2px;
      bottom:-2.5vmin;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
      content:"";
      left:-2.5vmin;
      position:absolute;
      right:-2.5vmin;
      top:-2.5vmin;
    } */
}

.text{
    width:50%;
    margin: 10px;
    word-wrap: normal;
    display:block;
    margin-left: 5%;
    text-align: justify;
    line-height: 1.5;
}

#gallery{
    border-radius: 25px;
    margin: 10px;
    box-sizing: border-box;
}

@media only screen and (max-width: 700px) {
    .venueSection{
        flex-direction: column;
    }
    .gallery{
        width: 100%
    }
    .text{
        width:100%;
        margin-left: 2%;
    }
}

h2, h3{
    text-align: center;
}