.forms-section {
  position: relative;
  z-index: 1;
  background-image: url(../images/full-width-image.jpg);
  background-size: cover;
  color: #fff;
  padding: 50px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.forms-section:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
}

.forms-section-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  flex-wrap: wrap;
  padding: 0 0 50px;
  box-sizing: border-box;
}

.forms-section-wrapper:last-child {
  padding: 0;
}

.forms-section-heading h2 {
  font-family: 'Playball', cursive;
  font-weight: normal;
  font-size: 50px;
  margin: 0;
  overflow: auto;
}

.forms-section-text {
  line-height: 1.5em;
  font-size: 18px;
}

.forms-section-button {
  color: #fff;
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  background: #890f3a;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  outline: 0;
  cursor: pointer;
  transition: all 0.4s ease 0s;
}

.forms-section-button:hover {
  background: #440018;
  letter-spacing: 1px;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
}

.forms-section-modal {
  width: 100%;
  height: 100%;
  padding: 50px 10px 10px;
  box-sizing: border-box;
}

.forms-section-modal-overlay {
  padding: 0;
}

.forms-section-modal-iframe-container {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.forms-section-modal-iframe {
  max-width: 100%;
  height: 99%;
}

.forms-section-modal-close {
  cursor: pointer;
  outline: 0;
}

@media (min-width: 768px) {
  .forms-section-modal {
    border-radius: 5px;
    padding: 1.2rem;
    padding-right: 50px;
    width: auto;
    max-width: 800px;
  }

  .forms-section-modal-overlay {
    padding: 1.2rem;
  }
}

@media (min-width: 992px) {
  .forms-section {
    padding: 70px 20px;
  }

  .forms-section-text {
    font-size: 23px;
  }

  .forms-section-button {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .forms-section-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 50px !important;
  }
}

@media (min-width: 1440px) {
  .forms-section-wrapper {
    padding: 0 100px !important;
  }
}
