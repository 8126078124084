.section {
  margin: 20px;
  box-sizing: border-box;
}

.section-text-wrapper {
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
}

/* .section-heading {
  padding-top: 30px;
  padding-bottom: 15px;
} */

.section-heading h2 {
  font-family: 'Great Vibes', cursive;
  font-weight: normal;
  font-size: 56px;
  margin: 0;
}

.section-separator {
  padding-bottom: 15px;
}

/* .section-heading:after,
.section-separator:after {
  content: '';
  background: url('../images/separator.png') no-repeat center center;
  height: 100px;
  display: block;
} */

.section-text {
  line-height: 1.5em;
  font-size: 16px;
}

.section-text ul {
  display: inline-block;
  text-align: left;
  margin: 0;
}

#pg{
  text-align: left;
}

.section-text h2 {
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 24px;
  text-align: left;
}

.section-text a {
  color: #890f3a;
  text-decoration: none;
}

.section-text a:hover {
  text-decoration: underline;
}

.titleImg{
  height: 10em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
